<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      Import/Update NPIs for NPI Match
    </v-card-title>
    <v-divider />
    <validation-observer ref="textInput">
      <v-row style="margin: 0;">
        <v-col cols="12" class="px-6">
          <div class="field__header mt-2">
            File to Import
          </div>
          <v-file-input
            v-model="importFile"
            class="file-input"
            accept="text/csv"
            label="Select File to Import"
            small-chips
            hint="Upload a CSV (Comma-Delimited) file"
            persistent-hint
            @change="setFile"
          />
        </v-col>
        <v-col
          v-if="importTemplateLink"
          cols="12"
          class="mt-3 px-6"
        >
          <a :href="importTemplateLink" target="_blank">Download a file template</a>
        </v-col>
      </v-row>
    </validation-observer>

    <v-card-actions class="divider-with-shadow d-flex justify-end align-center px-6 py-3">
      <div
        class="simple-text-button button--gray px-9"
        @click="$emit('dismiss')"
      >
        Cancel
      </div>

      <v-btn
        class="custom-button custom-button--blue px-15"
        height="34px"
        depressed
        @click="onImport"
      >
        Import
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "NpiMatchUploadDialog",
  data() {
    let apiUrl = process.env.VUE_APP_REST_ADDRESS;
    let importTemplateLink = apiUrl + 'npi_match/template';

    return {
      importFile: null,
      importTemplateLink: importTemplateLink
    };
  },
  methods: {
    async onImport() {
      const formData = new FormData();
      if (this.importFile) {
        formData.append('file', this.importFile);
      }

      await this.$rest.npiMatch.import(formData);

      this.$emit('file-uploaded');
    },
    async setFile(file) {
      if (!(file instanceof File)) {
        return;
      }

      this.importFile = file
    },
  },
};
</script>

<style lang="scss" scoped>
  .popup-card {
    .field__header {
      margin-bottom: 15px;
    }
    .v-input.v-text-field {
      max-height: 44px;
    }
    .v-btn__content {
      text-transform: none;
    }
    .v-card__title {
      font-family: 'Open Sans', sans-serif;
      font-size: 15px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15;
    }
    fieldset {
      border-radius: 4px;
      border: solid 1px #dee7ee !important;
      background-color: #ffffff;
    }
    .input-label {
      font-family: 'Open Sans', sans-serif;
      font-size: 13px !important;
      font-weight: 600 !important;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15
    }
  }
  .file-input {
    label {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      color: #66788e !important;
    }
  }
</style>
