<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      header-text="NPI Match"
      :regular-button-text="
        $store.getters['user/isPulseAdmin'] ? 'Import NPI File' : null
      "
      :outlined-button-text="
        $store.getters['user/isPulseAdmin'] ? 'Export All NPI\'s' : null
      "
      :regular-button-visible="$store.getters['user/isPulseAdmin'] && npiCount !== null"
      :outlined-button-visible="$store.getters['user/isPulseAdmin'] && npiCount !== null"
      @regular-button-click="showImportDialog = true;"
      @outlined-button-click="exportNpi"
    >
      <template #subheader>
        <p
          v-if="npiCount"
          class="dashboard-sub-headline"
        >
          There are currently a total of <span>{{ npiCount }}</span> NPI's available for matching.
        </p>
        <p
          v-else-if="npiCount === null"
          class="dashboard-sub-headline"
        >
          The NPI list is currently being updated. Please check again soon.
        </p>
      </template>
    </PageHeader>
    <v-row v-if="npiCount">
      <v-col cols="12" sm="4" class="py-0">
        <CustomDropdown
          v-model="primarySpecialty"
          header="Primary Specialty"
          placeholder="Count all Primary Specialties"
          :items="primarySpecialties"
          item-text="label"
          item-value="value"
          autocomplete
        />
      </v-col>
      <v-col cols="12" sm="4" class="py-0">
        <CustomDropdown
          v-model="secondarySpecialty"
          header="Secondary Specialty"
          placeholder="Count all Secondary Specialties"
          :items="secondarySpecialties"
          item-text="label"
          item-value="value"
          autocomplete
        />
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn
          class="custom-button custom-button--blue px-15"
          style="margin-top: 26px;"
          height="34px"
          depressed
          @click="getFilteredCount"
        >
          Count
        </v-btn>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn
          class="custom-button text-grey cursor-pointer"
          style="margin-top: 26px;"
          @click="primarySpecialty = null; secondarySpecialty = null; filteredCount = null;"
        >
          Reset
        </v-btn>
      </v-col>
      <v-col v-if="filteredCount" cols="12">
        Count matching search filters: <strong>{{ filteredCount }}</strong>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showImportDialog"
      scrollable
      max-width="560px"
      @click:outside="showImportDialog = false;"
    >
      <NpiMatchUploadDialog
        v-if="showImportDialog"
        @file-uploaded="startNpiCountCheck"
        @dismiss="showImportDialog = false;"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import NpiMatchUploadDialog from "./components/NpiMatchUploadDialog";

export default {
  name: "NpiMatchView",
  metaInfo: {
    title: 'NPI Match'
  },
  components: {
    PageHeader,
    CustomDropdown,
    NpiMatchUploadDialog,
  },
  data() {
    return {
      npiCount: 0,
      filteredCount: null,
      primarySpecialties: [],
      secondarySpecialties: [],
      showImportDialog: false,
      primarySpecialty: null,
      secondarySpecialty: null,
    };
  },
  mounted () {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        const resp = await this.$rest.npiMatch.getCount(null, true);
        this.npiCount = resp.data.count.toLocaleString();
      } catch (e) {
        if (e.response.status === 503) {
          this.npiCount = null;
          return;
        }
      }

      const spResp = await this.$rest.npiMatch.getSpecialties();
      this.primarySpecialties = spResp.data.primarySpecialties.map(sp => ({
        label: sp,
        value: sp,
      }));
      this.primarySpecialties.unshift({
        label: 'Count All',
        value: null,
      })
      this.secondarySpecialties = spResp.data.secondarySpecialties.map(sp => ({
        label: sp,
        value: sp,
      }));
      this.secondarySpecialties.unshift({
        label: 'Count All',
        value: null,
      })
    },
    async getFilteredCount() {
      const resp = await this.$rest.npiMatch.getCount({
        primarySpecialty: this.primarySpecialty,
        secondarySpecialty: this.secondarySpecialty,
      });

      this.filteredCount = resp.data.count.toLocaleString();
    },
    startNpiCountCheck() {
      this.showImportDialog = false;
      this.$store.commit("snackbar/showMessage", {
        content: "The import has been queued. Please wait for processing to complete.",
        color: "success"
      });

      let importStarted = false;

      this.getData();

      if (this.npiCount === null) {
        importStarted = true;
      }

      const interval = setInterval(() => {
        this.getData();

        if (!importStarted && this.npiCount === null) {
          importStarted = true;
        }

        if (importStarted && this.npiCount !== null) {
          clearInterval(interval);
        }
      }, 5000);
    },
    async exportNpi() {
      await this.$rest.exports.post_resource({
        exportType: 'npi',
      });

      await this.$router.push({name: "ExportsView"});
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-sub-headline {
  font-size: 16px;
  color: #241c15;
}

.dashboard-sub-headline span {
  font-weight: 600;
  color: #2b84eb;
}
</style>
